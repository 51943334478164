import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connectInfiniteHits, InfiniteHitsProvided, Hit } from 'react-instantsearch-core'

import { ArchiveIndexView } from './archive-index-view'
import { IMessageDoc } from '../../models/algolia/message'

interface IProps extends InfiniteHitsProvided<IMessageDoc> {
  hits: Array<Hit<IMessageDoc>>,
}

function ArchiveResults(props: IProps) {
  const { hits, hasMore, refineNext } = props

  return <InfiniteScroll
    className={ 'infinite-hits' }
    hasMore={ hasMore }
    loadMore={ () => {
      setTimeout(() => refineNext(), 0)
    } }
    element={'div'}
  >
    <ArchiveIndexView
      attribute='date_year'
      hits={hits}
      defaultRefinement={yearRefinement()}
      limit={100}
    />
  </InfiniteScroll>
}

const ArchiveSearchResults = connectInfiniteHits<IProps, IMessageDoc>(ArchiveResults)

export { ArchiveSearchResults }

function yearRefinement(): string {
  if(window.location.search) {
    const params = new URLSearchParams(window.location.search)
    const year = params.get('menu[date_year]')
    if(year) {
      return year
    }
  }

  return ''
}
